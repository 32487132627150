import * as React from "react";
import { Link, graphql } from "gatsby";
import "../static/styles/article.css";
import styled from "styled-components";
import Layout from "../components/layout";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
// import TableOfContents from "../components/tableOfContents";
import Seo from "../components/seo";
import StyledLinkButton from "../components/LinkButton";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import Box from "../components/Box";
deckDeckGoHighlightElement();

const ArticleContainer = styled.article``;

const ArticleHeading = styled.h1`
  margin: 4rem 0;
  text-align: center;
  font-size: 2rem;
`;

const BlogFooter = styled.footer`
  margin: 2rem 0;
  text-align: center;
`;

const LinkHeading = styled.h3`
  margin: 0;
`;

const ArticleLink = styled(Link)`
  display: block;
  margin: 1rem 0;
  color: #222;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BlogPostTemplate = (props) => {
  const { data, location } = props;
  const post = data.mdx;
  const { previous, next } = data;
  const twitterUrl = `https://www.twitter.com/share?url=${location.href}&text=${post.frontmatter.title}`;
  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <ArticleContainer>
        <ArticleHeading>{post.frontmatter.title}</ArticleHeading>
      </ArticleContainer>

      {/* <TableOfContents tableOfContents={post.tableOfContents} /> */}
      <MDXProvider>
        <main className="article-html">
          <MDXRenderer>{post.body}</MDXRenderer>
        </main>
      </MDXProvider>

      <BlogFooter>
        <StyledLinkButton
          bgColor="yellowgreen"
          href={twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Share on twitter
        </StyledLinkButton>
        <div style={{ margin: "150px 0 20px 0" }}>
          <h2>Recent Articles</h2>
          {previous ? (
            <Box style={{ marginBottom: "30px" }}>
              <ArticleLink to={`/` + previous.slug}>
                <LinkHeading>{previous.frontmatter.title}</LinkHeading>
              </ArticleLink>
            </Box>
          ) : null}

          {next ? (
            <Box>
              <ArticleLink to={`/` + next.slug}>
                <LinkHeading>{next.frontmatter.title}</LinkHeading>
              </ArticleLink>
            </Box>
          ) : null}
        </div>
      </BlogFooter>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      timeToRead
      tableOfContents(maxDepth: 2)
      frontmatter {
        title
        date
        description
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      slug
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      slug
      frontmatter {
        title
      }
    }
  }
`;
