import React from "react";
import styled from "styled-components";
const StyledButton = styled.a`
  padding: 0.8rem 1rem;
  background-color: white;
  border-radius: 1px;
  color: black;
  border: 2px solid #333;
  font-size: 1rem;
  font-family: "Miriam Libre";
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function Button(props) {
  return <StyledButton {...props}>{props.children}</StyledButton>;
}
